import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactDataTable from "../../../components/ReactDataTable/ReactDataTable";
import { getOrdersList } from "../../../features/order/orderSlice";
import { StyledContainer, StyledPageTitle, StyledOrderBlock } from "./style";

const AwaitingResponse = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const awaitingOrdersList = useSelector((state) => state?.order?.ordersList);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

   
    useEffect(() => {
        dispatch(getOrdersList({
            orderName: "awaitingorder",
            page: page,
            perPage: limit,
        })).unwrap().then((res) => {
            if (res.status === 401) {
                toast.error(`${res?.message}`, {
                    className: "toast-message",
                });
                localStorage.removeItem("user");
                navigate("/");
            }
        });
        document.title = "Awaiting Customer | UK ETA Visa Portal";
    }, [dispatch, navigate, page, limit]);

    const tableData = awaitingOrdersList;

    const handleClick = () => {
        window.location.reload();
    };
    return (
        <StyledContainer>
            <StyledPageTitle>
                <h1>Awaiting Response</h1>
                <button type="button" className="btn btn-success" onClick={handleClick}>Refresh</button>

            </StyledPageTitle>
            <form>
                <StyledOrderBlock>
                    <div className="table-block table-responsive">
                        <ReactDataTable
                            data={tableData}
                            setPage={setPage}
                            setLimit={setLimit}
                            orderName="awaitingorder"
                        />
                    </div>
                </StyledOrderBlock>
            </form>
        </StyledContainer>
    );
};

export default AwaitingResponse;
